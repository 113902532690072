<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
const { hireFormUrl } = useAppConfig();
const form = ref({
  keywords: "",
  location: "",
});

useSeoMeta({
  title: "Hire Truckers",
  ogTitle: "Hire Truckers",
  description: "Find Trucking Jobs, Employment & Career Opportunities.",
  ogDescription: "Find Trucking Jobs, Employment & Career Opportunities.",
});

function handleSearch() {
  useRouter().push({
    path: "/jobs/search",
    query: {
      ...form.value,
      radius: 60,
    },
  });
}
</script>
<template>
  <section class="relative w-full py-4 sm:h-[100vh] px-5">
    
<FormKitLazyProvider config-file="true">
<div class="absolute inset-0 bg-emerald-600/5 dark:bg-emerald-600/10"></div>
    <div class="container z-1">
      <div
        class="sm:flex sm:items-center sm:justify-center sm:h-[90vh] mt-24 sm:mt-0"
      >
        <div class="relative w-full">
          <div class="flex justify-center">
            <div class="flex flex-col items-center gap-6 md:flex-row">
              <HeroLogo />
              <div class="text-center md:text-left">
                <h4
                  class="text-2xl font-bold leading-tight md:text-4xl lg:leading-tight lg:text-5xl"
                >
                  Explore Thousands
                  <br />
                  of
                  <span class="font-bold text-primary">Truck Driving Jobs</span>
                </h4>
                <p class="text-lg text-slate-400">
                  Find Jobs, Employment & Career Opportunities.
                </p>
              </div>
            </div>
          </div>

          <div class="d-flex" id="reserve-form">
            <div class="mx-auto md:w-5/6">
              <div class="mt-8 lg:col-span-10">
                <div
                  class="p-3 bg-white border-0 rounded-md shadow dark:bg-slate-900 fields-no-outline"
                >
                  <FormKit
                    type="form"
                    :actions="false"
                    @submit="handleSearch"
                    v-model="form"
                  >
                    <div class="items-center gap-3 sm:flex">
                      <FormKit
                        type="text"
                        placeholder="Search"
                        name="keywords"
                        outer-class="mb-5 sm:mb-0 sm:w-2/5"
                      >
                        <template #prefixIcon>
                          <IconMagnifyingGlass class="w-6 mx-2 text-primary" />
                        </template>
                      </FormKit>

                      <FormKit
                        type="location"
                        placeholder="City, State, or Zip"
                        outer-class="mb-5 sm:mb-0 sm:w-2/5"
                        name="location"
                      >
                        <template #prefixIcon>
                          <IconMapPinOutline class="w-6 mx-2 text-primary" />
                        </template>
                      </FormKit>

                      <button class="w-full sm:w-1/5 btn btn-primary">
                        Search
                      </button>
                    </div>

                    <!--end container-->
                  </FormKit>
                </div>
              </div>
              <!--ed col-->
            </div>
          </div>
          <!--end grid-->

          <div
            class="items-center justify-center mt-4 mb-10 text-center md:flex md:mb-0"
          >
            <div>
              <span>Looking to hire? </span>
              <IconArrowRightCircleOutline
                class="inline-block w-5 ml-1 text-primary"
              />
            </div>

            <NuxtLink :to="hireFormUrl" class="ml-1 underline text-primary">
              Contact us about advertising
            </NuxtLink>
          </div>
        </div>
      </div>

      <!--end grid-->
    </div>
</FormKitLazyProvider>

    <!--end container-->
  </section>
</template>
